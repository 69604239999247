import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from './LiabilityStepDetails.module.scss';
import tickIcon from '../../assets/images/tickIcon.svg';
import crossIcon from '../../assets/images/crossIcon.svg';
import { dateFormat } from '../../utils/dateFormat';
import { NumberFormat } from '../../utils/currencyConverter';
import Accordion from 'react-bootstrap/Accordion'

interface ModalProps {
    show: boolean;
    onHide: () => void;
    policyDetails: any;
}

export const getCoverageDescription = (coverageName: string): string => {
    const coverageMap: { [key: string]: string } = {
      E: 'Employee Only',
      ESC: 'Employee, Spouse & Children',
      ESCP: 'Employee, Spouse, Children & Parents',
    };
  
    return coverageMap[coverageName] || 'Invalid coverage name';
  };

const LiabilityStepDetails = ({ show, onHide, policyDetails }: ModalProps) => {

    console.log(policyDetails)

    const standardCover = policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.[0]
        ?.standardCovers ?? [];

    let risks: any = [];

    policyDetails?.request_metadata?.locationBasedRiskAndCoverPackages?.forEach(
        (location: any) => {
            risks = risks.concat(location?.risks);
        },
    );

    const risksTotal =
        policyDetails?.insurance_type === "stock"
            ? risks
                ?.map((x: any) =>
                    [
                        'Raw Material',
                        'Stocks in Process',
                        'Finished Stocks',
                    ].includes(x?.riskName)
                        ? x?.sumInsured ?? 0
                        : 0,
                )
                ?.reduce((a: any, b: any) => a + b, 0) : risks
                    ?.map((x: any) =>
                        [
                            'Building Including Plinth & Foundation',
                            'Contents',
                            'Furniture Fixture & Fittings',
                            'Plant & Machinery',
                        ].includes(x?.riskName)
                            ? x?.sumInsured ?? 0
                            : 0,
                    )
                    ?.reduce((a: any, b: any) => a + b, 0);

    const handleModalClose = () => {
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className={styles.modalTitle}
                >
                    Step Details {' '}
                    {policyDetails?.purchasePolicyDetails?.cover?.riskName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    {
                        policyDetails?.stepNumber > 1 &&
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Basic Details </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>First Name</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.first_name  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Last Name</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.last_name  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Email</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.email  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Contact Number</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.phone_number  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                    </thead>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                       {
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Business Details </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    {/* <h5>Coverage </h5> */  }           

                                    <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>Legal Name</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.legalName || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Address</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.address || 'NA'
                                            }
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>Business Type</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.businessType|| 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Business Category</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.businessCategory|| 'NA'
                                            }
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>No Of Employees</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.noOfEmployee || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Annual Turnover</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.annualTurnover || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Funding Amount</td>
                                            <td colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.businessData?.fundingAmount || 'NA'
                                            }
                                            </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                 </Row>       

                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                    {
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Quote Details </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>
                                    {/* <h5>Coverage </h5> */}
                                    
                                    <Table bordered hover>
                                    <thead>
                                        <tr>
                                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Sr No</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Coverage Name</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>CoverSI</th>
                                        <th style={{ border: '1px solid #ddd', padding: '8px' }}>Premium</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {   policyDetails?.userState?.userStateData?.userSelectedData && 
                                        policyDetails?.userState?.userStateData?.userSelectedData?.products.map((product:any, index:number) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{index + 1}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{product.name}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{product.coverSI}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'right' }}>{product.premium}</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                                 
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                     {/* {
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Summary </Accordion.Header>
                            <Accordion.Body>
                                <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <thead>
                                            <tr style={{ textAlign: 'left' }}>
                                                <th colSpan={2}>Summary</th>
                                                <th colSpan={4}  
                                                style={{
                                                        textAlign: 'right',
                                                    }}
                                                >Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {policyDetails?.insurance_type !== "stock" && (
                                                <>
                                                    <tr>
                                                        <td colSpan={2}>
                                                        Subscription Fee
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            
                                                        { 
                                                            NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.selected_premium   ?? 0) ?? '-'
                                                        }

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Taxes & Cess</td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                        { 
                                                            NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.tax  ?? 0) ?? '-'
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            Promo Code 
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                           { 
                                                                <i><b>{policyDetails?.request_metadata?.recommendation_selection?.promoCodeName || 'NA'  }</b></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>
                                                        Your Total Saving
                                                        </td>
                                                        <td
                                                            colSpan={4}
                                                            style={{
                                                                textAlign: 'right',
                                                            }}
                                                        >
                                                            - { NumberFormat(policyDetails?.request_metadata?.recommendation_selection?.savings ?? 0) ?? '-' }
                                                        </td>
                                                    </tr>
                                                </>
                                            )}

                                          
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Grand Total</th>
                                                <th
                                                    colSpan={4}
                                                    style={{ textAlign: 'right' }}
                                                >
                                                    {NumberFormat( policyDetails?.request_metadata?.recommendation_selection?.gross_amount  ?? 0) ??
                                                        '-'}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>                                 
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    } */}

                    {/* { policyDetails?.purchase_metadata &&  */}
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Payment Details  </Accordion.Header>
                            <Accordion.Body>
                            <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>Transaction ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.paymentResponse?.transactionId || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Order ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.paymentResponse?.orderId || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Receipt ID</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                    policyDetails?.userState?.userStateData?.paymentResponse?.receiptId || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Payment Status</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.paymentResponse?.paymentStatus || 'NA'
                                            }
                                            </td>
                                        </tr>
                                     
                                        <tr>
                                            <td colSpan={2}>Payment Mode</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.paymentResponse?.mode  || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Card Type</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.paymentResponse?.cardType  || 'NA'
                                            }
                                            </td>
                                        </tr>
                                     
                                        <tr>
                                            <td colSpan={2}>Description</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                    policyDetails?.userState?.userStateData?.paymentResponse?.description  ?? 'NA'
                                            }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>    

                                </Row>
                            </Accordion.Body>    
                        </Accordion.Item>    

                {/*  KYC Details  */}
                <Accordion.Item eventKey="5">
                            <Accordion.Header>KYC Details  </Accordion.Header>
                            <Accordion.Body>
                            <Row style={{ padding: '20px' }}>                   
                                    <Table bordered hover>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>PAN Number</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.additionalKycDetails?.pan_number || 'NA'
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>GST Number</td>
                                            <td
                                                colSpan={4}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                            { 
                                                policyDetails?.userState?.userStateData?.additionalKycDetails?.gst_number || 'NA'
                                            }
                                            </td>
                                        </tr>
                                    </tbody>
                                   </Table>
                                </Row>           
                            </Accordion.Body>
                </Accordion.Item>

                {/*  Insured Details  */}
                <Accordion.Item eventKey="6">
                            <Accordion.Header>Insured Details </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>First Name</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.firstName  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Last Name</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.lastName  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Email</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.email  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Contact Number</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.mobileNumber  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Date Of Birth</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.dob  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Address</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.address  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Pincode</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.pincode  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>City</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.city  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>State</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalInsuredDetails?.state  ?? "NA"
                                                }
                                            </td>
                                        </tr>

                                    </thead>
                                </Table>
                            </Accordion.Body>
                </Accordion.Item>

                   {/*  Subsidiary Details  */}
                   { policyDetails?.userState?.userStateData?.has_subsidiary && (
                   <Accordion.Item eventKey="7">
                            <Accordion.Header>Subsidiary Details </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Territory</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.territory  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Jurisdiction</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.jurisdiction  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Count Of Premises</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalSubsidiaryDetails?.count_of_premises  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Subsidiary In India</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalSubsidiaryDetails?.subsidiaries_in_india  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Subsidiary In Canada</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalSubsidiaryDetails?.subsidiaries_us_canada  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}>Subsidiary In Worldwide</th>
                                            <td colSpan={6}>
                                                {
                                                    policyDetails?.userState?.userStateData?.additionalSubsidiaryDetails?.subsidiaries_worldwide  ?? "NA"
                                                }
                                            </td>
                                        </tr>
                                    </thead>
                                </Table>
                            </Accordion.Body>
                </Accordion.Item>
                )}

          



                    {/* }     */}
                </Accordion>
            </Modal.Body>
        </Modal>
    );
};

export default LiabilityStepDetails;
