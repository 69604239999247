import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { notify } from '../../common/Toast';
import styles from './CallBackRequest.module.scss';
import { dateFormat } from '../../utils/dateFormat';
import CallBackRequestStatusUpdate from '../../common/CallBackRequestStatusUpdate';
import editIcon from '../../assets/images/editIcon.svg';

interface Row {
    id: string;
    companyName: string;
    name: string;
    phone: string;
    createdAt: EpochTimeStamp;
    status: string;
    isRenew:string;
    industryType:string;
    employeeCount:string;
    noSupportIndustry:string;
    email:string;
    date:string;
    time:string;
}

const CallBackRequests = () => {
    const [CallBackRequests, setCallBackRequests] = useState<Row[]>([]);
    const [editCallBackRequestStatusOption, setEditCallBackRequestStatusOption] = useState(-1);
    const [selectedOption, setSelectedOption] = useState('pending');
    const [apiType, setApiType] = useState('SME'); // State for dropdown selection

    const fetchCallBackRequests = async () => {
        const apiUrl =
            apiType === 'SME'
                ? `${process.env.REACT_APP_BASE_URL}/v1/admins/users/callBack-Requests`
                : `${process.env.REACT_APP_GH_API_URL}/v1/admins/users/callBack-Requests`;

        try {
            const response = await axios.get(apiUrl);
            setCallBackRequests(response.data.data);
        } catch (error) {
            notify.error(`Error fetching data: ${error}`);
        }
    };

    const handleTickClick = async (callBackRequestId: string) => {
        try {
            const authData = JSON.parse(localStorage.getItem('authCheck') ?? 'null');
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authData.adminAuth}`,
            };
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v1/admins/users/callback-requests/${callBackRequestId}`,
                { status: selectedOption },
                { headers }
            );

            if (response.data.success) {
                fetchCallBackRequests();
                handleCrossClick();
                notify.success(`Status Updated for Call Back Request Id: ${callBackRequestId}`);
            }
        } catch (error) {
            notify.error('Error Updating Status!');
        }
    };

    const handleCrossClick = () => {
        setEditCallBackRequestStatusOption(-1);
        setSelectedOption('');
    };

    const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setApiType(e.target.value);
    };

    useEffect(() => {
        fetchCallBackRequests();
    }, [apiType]); // Refetch data whenever the API type changes

    return (
        <div className={styles.tab}>

            <div className={styles.dropdown}>
                <label htmlFor="apiType">Select Platform: </label>
                <select id="apiType" value={apiType} onChange={handleDropdownChange}>
                    <option value="SME">SME</option>
                    <option value="GH">GH</option>
                </select>
            </div>

            <Tabs defaultActiveKey="CallBackrequests" id="fill-tab-example">
                <Tab eventKey="CallBackrequests" title="Call Back Requests">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>User Name</th>
                                <th>Phone no.</th>
                                <th>Email</th>
                                <th>Is Renew</th>
                                <th>Industry Type</th>
                                <th>Not Supported Industry</th>
                                <th>Employee Count</th>
                                <th>Status</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CallBackRequests.map((row: Row, index: number) => (
                                <tr key={row.id}>
                                    <td>{row.companyName}</td>
                                    <td>{row.name}</td>
                                    <td>{row.phone}</td>
                                    <td>{row.email && row.email}</td>
                                    <td>{row.isRenew && row.isRenew ? 'YES' : 'NO' }</td>
                                    <td>{row.industryType && row.industryType}</td>
                                    <td>{row.noSupportIndustry && row.noSupportIndustry}</td>
                                    <td>{row.employeeCount && row.employeeCount}</td>
                                    
                                    <td>
                                        {editCallBackRequestStatusOption === index ? (
                                            <div className={styles.quoteUpdate}>
                                                <CallBackRequestStatusUpdate
                                                    selectedOption={row.status}
                                                    setSelectedOption={setSelectedOption}
                                                />
                                                <div onClick={() => handleTickClick(row.id)}>✅</div>
                                                <div onClick={handleCrossClick}>❌</div>
                                            </div>
                                        ) : (
                                            <>
                                                {row.status}
                                                <img
                                                    onClick={() => setEditCallBackRequestStatusOption(index)}
                                                    className={styles.editIcon}
                                                    src={editIcon}
                                                />
                                            </>
                                        )}
                                    </td>
                            

                                    <td>{dateFormat(row.createdAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        </div>
    );
};

export default CallBackRequests;
