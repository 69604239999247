// App.tsx
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import AdminLogin from './components/AdminLogin/AdminLogin';
import FormComponent from './components/AdminTool/AdminTool';
import Sidebar from './common/SideBar/Sidebar';
import MerchantCover from './components/MerchantCover/MerchantCover';
import CallBackRequests from './components/CallBackRequests/CallBackRequests';
import styles from './App.module.scss';
import SmeUsers from './components/SMEUsers/SmeUsers';
import GetQuotes from './components/Get Quotes/GetQuotes';
import GhUsers from './components/GHUsers/GhUsers';
import GHAdmin from './components/GHAdminTool/GhAdminTool';
import LiabilityUsers from './components/LiabilityUsers/LiabilityUsers';
import LiabilityAdmin from './components/LiabilityAdminTool/AdminTool';

const App: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [tool, setTool] = useState(0);
    useEffect(() => {
        const authData = JSON.parse(
            localStorage.getItem('authCheck') ?? 'null',
        );
        if (!authData || authData?.expireTime <= Date.now()) {
            localStorage.removeItem('AuthCheck');
            setIsLoggedIn(false);
        }
    }, []);

    const sideBarToggle = (index: number) => {
        setTool(index);
    };

    const renderContent = () => {
        switch (tool) {
            case 1:
                return <FormComponent />;
            case 2:
                return <CallBackRequests />;
            case 3:
                return <SmeUsers />;
            case 4:
                return <GetQuotes />;
            case 5:
                return <GhUsers />;
            case 6:
                return <GHAdmin />  
            case 7:
            return <LiabilityUsers />  
            case 8:
            return <LiabilityAdmin />    
            default:
                return <MerchantCover />;
        }
    };

    return (
        <div>
            <ToastContainer />
            {isLoggedIn ? (
                <>
                    <div className={styles.sidebar}>
                        <Sidebar sideBarToggle={sideBarToggle} />
                    </div>
                    {renderContent()}
                </>
            ) : (
                <AdminLogin onLogin={() => setIsLoggedIn(true)} />
            )}
        </div>
    );
};

export default App;
